.pagination-container {
    display: flex;
    list-style-type: none;
    justify-content: end;
  
    .pagination-item {
        background-color: #fff;
        border: 1px solid #dddfeb;
        color: #28a745;
        display: block;
        font-size: 14px;
        line-height: 1.25;
        margin-left: -1px;
        padding: 0.3rem 1rem;
        position: relative;
        margin-right: 5px;
        border-radius: 5px;
    
  
      &.dots:hover {
        background-color: transparent;
        cursor: default;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        cursor: pointer;
      }
  
      &.selected {
        background-color: #28a745;
        border-color: #28a745;
        color: #fff;
        z-index: 3;
      }
  
      .arrow {
        &::before {
          position: relative;
          /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
          content: '\f323';
          font-family: 'Font Awesome 5 Pro';
          /* By using an em scale, the arrows will size with the font */
          display: inline-block;


        }

      }
      .arrow.right{
        &::before {
          content: '\f324';
          font-family: 'Font Awesome 5 Pro';


        }
      }
  
      &.disabled {
        pointer-events: none;
        background-color: #fff;
    border-color: #dddfeb;
    color: #858796;
    cursor: auto;
    pointer-events: none;
  
       
  
        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }
    }
  }