body{
    margin: 0;
}
/* .et_pb_row .container {
    width: 80%;
    max-width: 1080px;
    margin: auto;
    position: relative;
    text-align: left;
} */
#main-header {
    line-height: 23px;
    font-weight: 500;
    top: 0;
    background-color: #fff;
    width: 100%;
    -webkit-box-shadow: 0 1px 0 rgba(0,0,0,.1);
    box-shadow: 0 1px 0 rgba(0,0,0,.1);
    position: relative;
    z-index: 99999;
    padding: 20px 0px;
}
.et_pb_section{
    background-color: #4a7b93;
    position: relative;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}
.et_pb_section h2 {
    margin-top: 0;
    color: #fff;
    text-align: center;
    font-family: "Questrial", sans-serif;
    font-size: 46px;
    font-weight: 500;
    margin-bottom: 0;
}
.footer-bottom {
    background-color: rgb(50 83 100);
    padding: 15px 0 5px;
}
.footer-bottom p{
    text-align: left;
    color: #fff;
    padding-bottom: 10px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    line-height: 24px;
}
.et_pb_row {
    padding: 50px 0px;
}
.et_pb_row p{
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #666;
}
.et_pb_row ol{
    padding-left: 10px;
}
.et_pb_row ol li, .et_pb_row ul li{
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #666;
}
.et_pb_row ol li strong{
    font-weight:700;
}
.et_pb_row h3{
    color: #4a7b93;
}
.et_pb_section2{
    background-color: #008fbb;
    position: relative;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}
.et_pb_section2 h2 {
    margin-top: 0;
    color: #fff;
    text-align: center;
    font-family: "Questrial", sans-serif;
    font-size: 46px;
    font-weight: 500;
    margin-bottom: 0;
}
.img-tg img {
    max-width: 518px;
}
.footer-bottom2 {
    background-color: #006884;
    padding: 15px 0 5px;
}
.footer-bottom2 p{
    text-align: left;
    color: #fff;
    padding-bottom: 10px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    line-height: 24px;
}
.et_pb_row table {
    caption-side: bottom;
    border-collapse: collapse;
    width: 100%;
}
.et_pb_row table {
    caption-side: bottom;
    border-collapse: collapse;
    width: 100%;
}
.et_pb_row table tr td {
    border: 1px solid #00000029;
    padding: 10px;
}
h2.et_pb_slide_title.ecc {
    text-align: left;
    max-width: 494px;
}
h2.et_pb_slide_title.ecc img{
    max-width: 518px;
    width: 100%;
}
.img-col {
    display: flex;
    justify-content: space-between;
    margin-bottom: 77px;
}
.et_pb_row h3 {
    font-size: 22px;
}
.et_pb_row p a {
    text-decoration: none;
    color: #3b87ae;
}
@media screen and (max-width: 991px) {
    .img-tg img {
        max-width: 363px;
        width: 100%;
    }
    .et_pb_section h2 {
        font-size: 30px;
    }
  }
@media screen and (max-width: 575px) {
    h2.et_pb_slide_title.ecc {
        margin-bottom: 29px;
    }
    .img-col {
        display: block;
    }
}
